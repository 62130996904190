import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'


export const ContactPageTemplate = ({ title, image, content, contentComponent, helmet }) => {
  const PageContent = contentComponent || Content

  const slider_settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <section className="section section--gradient">
    {helmet || ''}
      <div className="container is-max-desktop">
        <div className="content">
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
            style={{
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            {title}
          </h1>
            <img
              src={!!image.childImageSharp ? image.childImageSharp.fluid.src : image}
              style={{borderRadius: '10px'}}
            />
          <div className="py-3">
            <div className="columns">
              <div className="column is-6">
                <PageContent className="content" content={content} />
              </div>
              <div className="column is-6">
                <h3>Itinéraire</h3>
                <a href="https://goo.gl/maps/eHcaaNLk6ihpLENFA"><img src="https://maps.googleapis.com/maps/api/staticmap?center=Centre+Médical+St.+Hubert&zoom=14&size=600x300&maptype=roadmap
&markers=color:red%7Clabel:C%7C45.502482,-73.4272061
&key=AIzaSyA5Yo1I1reClUx1D6H3TMUnRA3ENzUkLPQ" /></a>
                <p><small>Cliquez sur l'image pour obtenir les directions</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ContactPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  image_gallery: PropTypes.array,
  contentComponent: PropTypes.func,
}

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | GMF Centre Médical St-Hubert">
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        image_gallery={post.frontmatter.image_gallery}
        content={post.html}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
        }
      }
    }
  }
`